<template>
  <div>
    <div class="title-bar">
        <div>
            <button class="btn btn-warning btn-labeled pull-right" type="button" @click="newTouchPoint()">
                <span class="btn-label">
                    <span class="icon icon-plus icon-lg icon-fw"></span>
                </span>
                Nuovo
            </button>
        </div>
        <h1 class="title-bar-title">
            Touch Point
        </h1>
        <p class="title-bar-description">
            <small>Gestione touch point per la progettazione dei planogrammi</small>
        </p>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="table-responsive">
                        <table id="dt_touchpoints" class="table table-middle nowrap">
                            <thead>
                            <tr>
                                <th class="dt-no-sort" width="100px">Img</th>
                                <th>Nome</th>
                                <th>Ripiani</th>
                                <th>Facing</th>
                                <th>Gruppo</th>
                                <th class="dt-no-sort"><i class="icon icon-cog"></i></th>
                            </tr>
                            </thead>
                            <tbody id="dtbody">
                            <!-- <tr v-if="items.length==0">
                                <td colspan="5">Nessun touch point trovato</td>
                            </tr> -->
                            <tr v-for="i in items" :key="i.id">
                                <td align="center"><img class="grid-preview" :src="i.immagine"></td>
                                <td>{{i.nome}}</td>
                                <td>{{i.righe}}</td>
                                <td>{{i.colonne}}</td>
                                <td>{{i.gruppo!=null ? i.gruppo.nome : ''}}</td>
                                <td>
                                    <i @click="edit(i.id)" class="icon icon-edit"></i>
                                    &nbsp;&nbsp;
                                    <i @click="remove(i.id)" class="icon icon-remove"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    
    <div class="modal fade bs-modal-lg modal-scroll" id="dlgFormTP" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Touch Point</h4>
                </div>
                
                <div class="modal-body">
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <input class="md-form-control" type="text" v-model="item.nome" required>
                            <label class="md-control-label">Nome</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <select class="md-form-control" v-model="gruppi_id" @change="sottogruppi_id=null">
                                <option :value="null"></option>
                                <option v-for="i in gruppi.filter(A => A.gruppi_id==null)" :key="i.id" :value="i.id">{{ i.nome}}</option> 
                            </select>
                            <label class="md-control-label">Gruppo</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2 md-form-group md-label-static">
                            <select class="md-form-control" v-model="sottogruppi_id">
                                <option :value="null"></option>
                                <option v-if="gruppi_id!=null" v-for="i in gruppi.filter(A => A.gruppi_id==gruppi_id)" :key="i.id" :value="i.id">{{ i.nome}}</option>
                            </select>
                            <label class="md-control-label">Sottogruppo</label>
                        </div>
                    </div>

                    <!--
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <input class="md-form-control" type="text" v-model="item.nome" required>
                            <label class="md-control-label">Nome</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <select class="md-form-control" v-model="item.gruppi_id">
                                <option :value="null"></option>
                                <option v-for="i in gruppi" :key="i.id" :value="i.id">{{ i.nome}}</option>
                            </select>
                            <label class="md-control-label">Gruppo</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    -->
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <input class="md-form-control" type="number" v-model="item.righe" required>
                            <label class="md-control-label">Ripiani</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <input class="md-form-control" type="number" v-model="item.colonne" required>
                            <label class="md-control-label">Facing</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-2 md-form-group md-label-static">
                            <select class="md-form-control" v-model="item.trasparente">
                                <option :value="false">No</option>
                                <option :value="true">Si</option>
                            </select>
                            <label class="md-control-label">Layer</label>
                        </div>
                    </div>
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-4 md-form-group">
                            <label>Immagine</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-4 md-form-group">
                            <label v-if="item.immagine==null || item.immagine==''" class="file-upload-btn btn btn-primary">
                                <i class="icon icon-image"></i> 
                                <input class="file-upload-input" type="file" name="icon" @change="imageSelected($event)" accept="image/*">
                            </label>
                            <div v-else>
                                <img :src="item.immagine" class="preview">
                                <i @click="imageRemove()" class="icon icon-4x icon-minus-circle"></i> 
                            </div>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="saveItem()">Salva</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>    
    

  </div>
</template>
<script>
export default {
    data(){
        return {
            items: [],
            gruppi: [],
            gruppi_id: null,
            sottogruppi_id: null,
            dataTable: null,
            item: {
                id: null,
                nome: '',
                righe: 1,
                colonne: 1,
                trasparente: false,
                gruppi_id: null
            }
        }
    },
    methods: {
        imageSelected(e){
            const file = e.target;
            if(file.files && file.files.length>0){
                const reader = new FileReader();
                reader.onload = () => {
                const imgData = reader.result;
                try {
                    this.item.immagine = imgData;
                    this.$forceUpdate();
                } catch(ex){}
                };
                reader.readAsDataURL(file.files[0]);
            }
            file.value = '';
        },
        imageRemove(){
            this.item.immagine = null;
            this.$forceUpdate();
        },
        newTouchPoint(){
            this.item = {
                id: null,
                nome: '',
                righe: 1,
                colonne: 1,
                trasparente: false,
                gruppi_id: null
            };
            this.gruppi_id = null;
            this.sottogruppi_id = null;
            this.$showModal('dlgFormTP');
        },
        edit(id){
            this.item = this.items.find(A => A.id==id);
            if(this.item.gruppo && this.item.gruppo.gruppi_id!=null){
                this.gruppi_id = this.item.gruppo.gruppi_id;
                this.sottogruppi_id = this.item.gruppo.id;
            } else {
                this.gruppi_id = this.item.gruppi_id;
            }
            this.$showModal('dlgFormTP');
        },
        remove(id){
            Swal.fire({
                title: 'Vuoi eliminare il touch point?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    fetch('/touchpoint/delete/' + id, {
                        method: 'DELETE',
                    }).then(res => {
                        if(res.ok){
                            this.dataTable.destroy();
                            const ix = this.items.findIndex(A => A.id==id);
                            this.items.splice(ix, 1);
                            this.$forceUpdate();
                            this.$nextTick(() => this.initDatatable());
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        toastr.error(err, 'Errore');
                    });
                }
            });
        },
        saveItem(){
            const img = this.item.immagine;
            const ixBase64 = img.indexOf('base64,');
            let immaginebase64 = '';
            let tipoImmagine = 'ok';
            if(ixBase64>=0){
                immaginebase64 = img.substring(ixBase64+7);
                tipoImmagine = img.substring(11, ixBase64-1);
                this.item.immagine = immaginebase64;
            }
            this.item.righe = +this.item.righe;
            this.item.colonne = +this.item.colonne;
            this.item.gruppi_id = (this.sottogruppi_id!=null ? this.sottogruppi_id : this.gruppi_id);
            fetch('/touchpoint/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'tipoImmagine': tipoImmagine},
                body: JSON.stringify(this.item)
            }).then(res => {
                if(res.ok){
                    res.json().then(r => {
                        // this.dataTable.destroy();
                        // if(this.item.id==null){
                        //     this.dataTable = null;
                        //     this.items.push(r);
                        // } else {
                        //     const ix = this.items.findIndex(A => A.id==this.item.id);
                        //     this.items[ix] = r;
                        // }
                        // this.$forceUpdate();
                        // this.$hideModal();
                        // this.$nextTick(() => this.initDatatable());
                        // toastr.success('Salvataggio Effettuato', this.$app_title);
                        window.location.reload();
                    });
                } else {
                    toastr.error(res.statusText, 'Errore');
                }
            }).catch(err => {
                toastr.error(err, 'Errore');
            });
        },
        initDatatable(){
           if(this.dataTable==null){
                this.dataTable = $('#dt_touchpoints').DataTable({
                    language: this.$dt_language,
                    order: [[ 1, "asc" ]]
                });
            } else {
                // this.dataTable.destroy();
                this.$nextTick(() => this.dataTable = $('#dt_touchpoints').DataTable({
                    language: this.$dt_language,
                    order: [[ 1, "asc" ]]
                }));
            }

        }
    },
    mounted(){
        console.info('TouchPoint mounted');
        fetch('/touchpoint/list').then(res => {
            res.json().then(r => {
                this.items = r;
                this.$nextTick(() => this.initDatatable());
            });
        });
        // $('#dt_touchpoints').DataTable({
        //     serverSide: true,
        //     processing: true,
        //     ajax: {
        //         url: '/touchpoint/dtlist',
        //         type: "POST"
        //     },
        //     columns: [
        //         { data: "nome", title: "Nome" },
        //         { data: "righe", title: "Righe" },
        //         { data: "colonne", title: "Colonne"}, //Allow post TSQL server side processing
        //         { data: "gruppi_id", visible: false },
        //         // { data: "Children", title: "Children" }
        //     ]
        // });
        fetch('/gruppi/list').then(res => {
            res.json().then(r => this.gruppi = r);
        })
        this.$initApp();
        this.$initElephant();
    }
}
</script>

<style scoped>
img.preview {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
}
img.grid-preview {
    max-width: 100%;
    max-height: 80px;
    object-fit: contain;
}
i.icon-4x {
    right: 10px;
    top: 10px;
    position: absolute;
}
</style>
