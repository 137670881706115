var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-bar" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-warning btn-labeled pull-right",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.newProgetto()
              }
            }
          },
          [_vm._m(0), _vm._v("\n              Nuovo\n          ")]
        )
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "title-bar-title" }, [
        _vm._v("\n          Progetti\n      ")
      ]),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c("div", { staticClass: "panel" }, [
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass: "table table-middle nowrap",
                  attrs: { id: "dt_progetti" }
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { attrs: { id: "dtbody" } },
                    _vm._l(_vm.items, function(i) {
                      return _c("tr", { key: i.id }, [
                        _c("td", [_vm._v(_vm._s(i.nome))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(i.num_scansie))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$formatDate(i.last_change)))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(i.user_last_change))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/progetti/dettaglio/" + i.id } },
                              [_c("i", { staticClass: "icon icon-edit" })]
                            ),
                            _vm._v(
                              "\n                                    \n                                  "
                            ),
                            _c("i", {
                              staticClass: "icon icon-remove",
                              on: {
                                click: function($event) {
                                  return _vm.remove(i.id)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-plus icon-lg icon-fw" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "title-bar-description" }, [
      _c("small", [_vm._v("Gestione progetti")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", [_vm._v("Nr. moduli")]),
        _vm._v(" "),
        _c("th", [_vm._v("Ultima modifica")]),
        _vm._v(" "),
        _c("th", [_vm._v("Utente")]),
        _vm._v(" "),
        _c("th", { staticClass: "dt-no-sort" }, [
          _c("i", { staticClass: "icon icon-cog" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }