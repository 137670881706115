<template>
  <!-------------------    HEADER   ----------------------->
<div class="layout-header">
    <div class="navbar navbar-default">
        <div class="navbar-header">
            <router-link class="navbar-brand navbar-brand-center" to="/">Planoweb</router-link>
            <!--
            <button class="navbar-toggler collapsed visible-xs-block" type="button" data-toggle="collapse" data-target="#sidenav">
                <span class="sr-only">Toggle navigation</span>
                <span class="bars">
                  <span class="bar-line bar-line-1 out"></span>
                  <span class="bar-line bar-line-2 out"></span>
                  <span class="bar-line bar-line-3 out"></span>
                </span>
                <span class="bars bars-x">
                  <span class="bar-line bar-line-4"></span>
                  <span class="bar-line bar-line-5"></span>
                </span>
            </button>
            <button class="navbar-toggler collapsed visible-xs-block" type="button" data-toggle="collapse"
                    data-target="#navbar">
                <span class="sr-only">Toggle navigation</span>
                <span class="arrow-up"></span>
                <span class="ellipsis ellipsis-vertical">
                    <i class="ellipsis-object icon icon-user"></i>
                </span>
            </button>
            -->
        </div>
        <div class="navbar-toggleable">
            <nav id="navbar" class="navbar-collapse collapse">
                <!--
                <button class="sidenav-toggler hidden-xs" title="Collapse sidenav ( [ )" type="button">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="bars">
                    <span class="bar-line bar-line-1 out"></span>
                    <span class="bar-line bar-line-2 out"></span>
                    <span class="bar-line bar-line-3 out"></span>
                    <span class="bar-line bar-line-4 in"></span>
                    <span class="bar-line bar-line-5 in"></span>
                    <span class="bar-line bar-line-6 in"></span>
                  </span>
                </button>
                -->
                <ul class="nav navbar-nav navbar-left">
                    <li>
                        <router-link to="/progetti">
                            <span class="sidenav-icon icon icon-product-hunt"></span>
                            <span class="sidenav-label">Progetti</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/gruppi">
                            <span class="sidenav-icon icon icon-list"></span>
                            <span class="sidenav-label">Gruppi</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/touchpoint">
                            <span class="sidenav-icon icon icon-th"></span>
                            <span class="sidenav-label">Touch Point</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/utenti">
                            <span class="sidenav-icon icon icon-users"></span>
                            <span class="sidenav-label">Utenti</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    
                    <li class="visible-xs-block">
                        <h4 class="navbar-text text-center">{{this.$parent.user}}</h4>
                    </li>
                    
                    <li class="dropdown hidden-xs">
                        <button class="navbar-account-btn" data-toggle="dropdown" aria-haspopup="true" @click="userMenu()">

                            <span class="circle bg- sq-32">
                                <span class="icon icon-user"></span>
                            </span>
                            {{this.$parent.user}}
                            <span class="caret"></span>
                        </button>
                        <ul id="logutmenu" class="dropdown-menu dropdown-menu-right closed">
                        <!--    <li><a href="profile.html">Profile</a></li>-->
                            <li>
                                <a href="/Account/Login/logout">
                                    <i class="icon icon-sign-out"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="visible-xs-block">
                        <a href="/logout">
                            <i class="icon icon-sign-out"></i> Logout
                        </a>
                    </li>
                    
                </ul>
            </nav>
        </div>
    </div>
</div>
<!-------------------    HEADER   ----------------------->
</template>

<script>
export default {
    methods: {
        userMenu(){
            if($('#logutmenu').hasClass('closed')){
                $('#logutmenu').css('display', 'block');
                $('#logutmenu').removeClass('closed');
            } else {
                $('#logutmenu').css('display', 'none');
                $('#logutmenu').addClass('closed');
            }
        }
    }
}
</script>

<style>

</style>