<template>
  <div>
    <div class="title-bar">
        <div>
            <button class="btn btn-warning btn-labeled pull-right" type="button" @click="newUtente()">
                <span class="btn-label">
                    <span class="icon icon-plus icon-lg icon-fw"></span>
                </span>
                Nuovo
            </button>
        </div>
        <h1 class="title-bar-title">
            Utenti
        </h1>
        <p class="title-bar-description">
            <small>Gestione utenti</small>
        </p>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="table-responsive">
                        <table id="dt_utenti" class="table table-middle nowrap">
                            <thead>
                            <tr>
                                <!-- <th>Id</th> -->
                                <th width="20%">Nome</th>
                                <th width="20%">Cognome</th>
                                <th width="50%">Email</th>
                                <!-- <th>Gruppo Padre</th> -->
                                <th class="dt-no-sort"><i class="icon icon-cog"></i></th>
                            </tr>
                            </thead>
                            <tbody id="dtbody">
                            <tr v-for="i in items" :key="i.id">
                                <!-- <td>{{i.Id}}</td> -->
                                <td>{{i.firstName}}</td>
                                <td>{{i.lastName}}</td>
		                        <td>{{i.email}}</td>
                                
                                <td>
                                    <i @click="edit(i.id)" class="icon icon-edit"></i>
                                    &nbsp;&nbsp;
                                    <i @click="changePassword(i.id)" class="icon icon-key"></i>
                                    &nbsp;&nbsp;
                                    <i @click="remove(i.id)" class="icon icon-remove"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    
    <div class="modal fade bs-modal-lg modal-scroll" id="dlgFormUtenti" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Utente</h4>
                </div>
                
                <div class="modal-body">
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-4 md-form-group md-label-static">
                            <input class="md-form-control" type="text" v-model="item.firstName" required>
                            <label class="md-control-label">Nome</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-1">&nbsp;</div>                        
                        <div class="col-md-4 md-form-group md-label-static">
                            <input class="md-form-control" type="text" v-model="item.lastName" required>
                            <label class="md-control-label">Cognome</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-10 md-form-group md-label-static">
                            <input class="md-form-control" type="email" v-model="item.email" required :disabled="item.id!=null">
                            <label class="md-control-label">Email</label>
                        </div>                  
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    <div class="row agutter-lg" v-if="item.id==null">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-4 md-form-group md-label-static">
                            <input class="md-form-control" type="password" v-model="password" required>
                            <label class="md-control-label">Password</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-1">&nbsp;</div>                        
                        <div class="col-md-4 md-form-group md-label-static">
                            <input class="md-form-control" type="password" v-model="password2" required>
                            <label class="md-control-label">Conferma Password</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="saveItem()">Salva</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div> 

    <div class="modal fade bs-modal-lg modal-scroll" id="dlgFormUtentiPwd" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Cambio Password</h4>
                </div>
                
                <div class="modal-body">
                    
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-10 md-form-group md-label-static">
                            <input class="md-form-control" type="password" v-model="passwordAttuale" required>
                            <label class="md-control-label">Password attuale</label>
                        </div>                  
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-10 md-form-group md-label-static">
                            <input class="md-form-control" type="password" v-model="password" required>
                            <label class="md-control-label">Nuova Password</label>
                        </div>                  
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    <div class="row agutter-lg">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-10 md-form-group md-label-static">
                            <input class="md-form-control" type="password" v-model="password2" required>
                            <label class="md-control-label">Conferma Password</label>
                        </div>                  
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="confirmChangePassword()">Conferma</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>    
    

  </div>
</template>
<script>
export default {
    data(){
        return {
            password: '',
            password2: '',
            passwordAttuale: '',
            dataTable: null,
            items: [],
            item: {
                id: null,
                firstName: '',
                lastName: '',
                email: ''
            }
        }
    },
    methods: {
        initDatatable(){
            // setTimeout(() => {
            if(this.dataTable==null){
                this.dataTable = $('#dt_utenti').DataTable({
                    language: this.$dt_language
                });
            } else {
                // this.dataTable.destroy();
                this.$forceUpdate();
                this.$nextTick(() => this.dataTable = $('#dt_utenti').DataTable({
                    language: this.$dt_language
                }));
            }
            // }, 300);

        },
        newUtente(){
            this.password = '';
            this.password2 = '';
            this.item = {
                id: null,
                firstName: '',
                lastName: '',
                email: ''
            };
            this.$showModal('dlgFormUtenti');
        },
        edit(id){
            this.item = this.items.find(A => A.id==id);
            this.$showModal('dlgFormUtenti');
        },
        changePassword(id){
            this.item = this.items.find(A => A.id==id);
            this.passwordAttuale = '';
            this.password = '';
            this.password2 = '';
            this.$showModal('dlgFormUtentiPwd');
        },
        remove(id){
            Swal.fire({
            title: 'Vuoi eliminare l\'utente?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    fetch('/utenti/delete/' + id, {
                        method: 'DELETE',
                    }).then(res => {
                        if(res.ok){
                            this.dataTable.destroy();
                            const ix = this.items.findIndex(A => A.id==id);
                            this.items.splice(ix, 1);
                            this.$nextTick(() => this.initDatatable());
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        toastr.error(err, 'Errore');
                    })
                }
            })
        },
        saveItem(){
            let headers = {'Content-Type': 'application/json'};
            let msg = 'Campi obbligatori:<br>';
            let campi = [];
            if(this.item.lastName==''){
                campi.push('Cogome');
            }
            if(this.item.firstName==''){
                campi.push('Nome');
            }
            if(this.item.email==''){
                campi.push('Email');
            }

            if(this.item.id==null){
                if(this.password==''){
                    campi.push('Password');
                }
                if(this.password2==''){
                    campi.push('Conferma Password');
                }
                headers['userPwd'] = this.password;
            } else {
                this.password = '';
                this.password2 = '';
            }

            if(campi.length>0){
                toastr.error(msg + campi.join('<br>'), 'Errore');
                return;
            }

            if(this.password.length>0 && this.password.length<6){
                toastr.error('La password deve essere lunga almeno 6 caratteri', 'Errore');
                return;
            }

            if(this.password2!=this.password){
                toastr.error('La password e la conferma non coincidono', 'Errore');
                return;
            }
            fetch('/utenti/save', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(this.item)
            }).then(res => {
                if(res.ok){
                    res.json().then(r => {
                        this.dataTable.destroy();
                        if(this.item.id==null){
                            this.items.push(r);
                        } else {
                            const ix = this.items.findIndex(A => A.id==this.item.id);
                            this.items[ix] = r;
                        }
                        this.$forceUpdate();
                        this.$hideModal();
                        this.$nextTick(() => this.initDatatable());
                        toastr.success('Salvataggio Effettuato', this.$app_title);
                    });
                } else {
                    res.json().then(T => toastr.error(T.title, 'Errore'));
                }
            }).catch(err => {
                toastr.error(err, 'Errore');
            });
        },
        confirmChangePassword(){
            let msg = 'Campi obbligatori:<br>';
            let campi = [];
            if(this.passwordAttuale==''){
                campi.push('Password Attuale');
            }
            if(this.password==''){
                campi.push('Password');
            }
            if(this.password2==''){
                campi.push('Conferma Password');
            }
            
            if(campi.length>0){
                toastr.error(msg + campi.join('<br>'), 'Errore');
                return;
            }

            if(this.password.length>0 && this.password.length<6){
                toastr.error('La password deve essere lunga almeno 6 caratteri', 'Errore');
                return;
            }

            if(this.password2!=this.password){
                toastr.error('La password e la conferma non coincidono', 'Errore');
                return;
            }

            let headers = {'Content-Type': 'application/json'};
            headers['id'] = this.item.id;
            headers['currentPwd'] = this.passwordAttuale;
            headers['newPwd'] = this.password;
        

            fetch('/utenti/changepassword', {
                method: 'POST',
                headers: headers
            }).then(res => {
                if(res.ok){
                    this.$hideModal();
                    toastr.success('Cambio Passowrd Effettuato', this.$app_title);
                } else {
                    res.json().then(T => toastr.error(T.title, 'Errore'));
                }
            }).catch(err => {
                toastr.error(err, 'Errore');
            });
        }
    },
    mounted(){
        console.info('Utene mounted');
        fetch('/utenti/list').then(res => {
            res.json().then(r => { 
                this.items = r;
                this.$nextTick(() => this.initDatatable());
            });
        });
        this.$initApp();
        this.$initElephant();
    }
}
</script>
