<template>
  <div>
    <div class="title-bar">
        <div>
            <button class="btn btn-warning btn-labeled pull-right" type="button" @click="newProgetto()">
                <span class="btn-label">
                    <span class="icon icon-plus icon-lg icon-fw"></span>
                </span>
                Nuovo
            </button>
        </div>
        <h1 class="title-bar-title">
            Progetti
        </h1>
        <p class="title-bar-description">
            <small>Gestione progetti</small>
        </p>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="table-responsive">
                        <table id="dt_progetti" class="table table-middle nowrap">
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Nr. moduli</th>
                                <th>Ultima modifica</th>
                                <th>Utente</th>
                                <th class="dt-no-sort"><i class="icon icon-cog"></i></th>
                            </tr>
                            </thead>
                            <tbody id="dtbody">
                            <!-- <tr v-if="items.length==0">
                                <td colspan="3">Nessun progetto trovato</td>
                            </tr> -->
                            <tr v-for="i in items" :key="i.id">
                                <td>{{i.nome}}</td>
                                <td>{{i.num_scansie}}</td>
                                <td>{{$formatDate(i.last_change)}}</td>
                                <td>{{i.user_last_change}}</td>
                                <td>
                                    <router-link :to="'/progetti/dettaglio/'+i.id">
                                        <i class="icon icon-edit"></i>
                                    </router-link>
                                    &nbsp;&nbsp;
                                    <i @click="remove(i.id)" class="icon icon-remove"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return {
            dataTable: null,
            items: [],
            item: {
                id: null,
                nome: '',
                num_scansie: null
            }
        }
    },
    methods: {
        initDatatable(){
            if(this.dataTable==null){
                this.dataTable = $('#dt_utenti').DataTable({
                    language: this.$dt_language
                });
            } else {
                // this.dataTable.destroy();
                this.$forceUpdate();
                this.$nextTick(() => this.dataTable = $('#dt_utenti').DataTable({
                    language: this.$dt_language
                }));
            }
            // if(this.dataTable==null){
            //     this.dataTable = $('#dt_progetti').DataTable({
            //         language: this.$dt_language
            //     });
            // } else {
            //     this.dataTable.destroy();
            //     this.$nextTick(() => this.dataTable = $('#dt_progetti').DataTable({
            //         language: this.$dt_language
            //     }));
            // }
        },
        newProgetto(){
            // this.item = {
            //     id: null,
            //     nome: '',
            //     num_scansie: null
            // };
            // this.$showModal('dlgForm');
            // this.$router.push('/progetti/dettaglio');
            location.href='/progetti/dettaglio';
        },
        remove(id){
            Swal.fire({
            title: 'Vuoi eliminare il progetto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    fetch('/progetti/delete/' + id, {
                        method: 'DELETE',
                    }).then(res => {
                        if(res.ok){
                            const ix = this.items.findIndex(A => A.id==id);
                            this.items.splice(ix, 1);
                            this.$forceUpdate();
                            this.$nextTick(() => this.initDatatable());
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        toastr.error(err, 'Errore');
                    })
                }
            })
        },
        saveItem(){
            fetch('/gruppi/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(this.item)
            }).then(res => {
                if(res.ok){
                    res.json().then(r => {
                        if(this.item.id==null){
                            this.items.push(r);
                        } else {
                            const ix = this.items.findIndex(A => A.id==this.item.id);
                            this.items[ix] = r;
                            this.$forceUpdate();
                        }
                        this.$hideModal();
                        this.$nextTick(() => this.initDatatable());
                        toastr.success('Salvataggio Effettuato', this.$app_title);
                    });
                } else {
                    toastr.error(res.statusText, 'Errore');
                }
            }).catch(err => {
                toastr.error(err, 'Errore');
            });
        }
    },
    mounted(){
        console.info('Progetto mounted');
        fetch('/progetti/list').then(res => {
            res.json().then(r => { 
                this.items = r;
                this.$nextTick(() => this.initDatatable());
            });
        });
        this.$initApp();
        this.$initElephant();
    }
}
</script>

<style>

</style>