
import Vue from 'vue';
import VueRouter from 'vue-router';
import Swal from 'sweetalert2';
import moment from 'moment';

Vue.use(VueRouter);
Vue.prototype.$app_title = 'Planoweb';
Vue.prototype.$showModal = (modalId) => {
    if(modalId[0]!='#'){
        modalId = '#' + modalId;
    }
    $('.layout-main').append($(modalId));
    $(modalId).modal('show');
    setTimeout(() => $(modalId+' .md-form-control').trigger('change'), 300);
};
Vue.prototype.$hideModal = () => {
    $('.modal').modal('hide');
    // $('.modal').html('');
};
window.Swal = Swal;
Vue.prototype.$dt_language = {
    "sEmptyTable":     "Nessun dato presente nella tabella",
    "sInfo":           "Vista da _START_ a _END_ di _TOTAL_ elementi",
    "sInfoEmpty":      "Vista da 0 a 0 di 0 elementi",
    "sInfoFiltered":   "(filtrati da _MAX_ elementi totali)",
    "sInfoPostFix":    "",
    "sInfoThousands":  ".",
    "sLengthMenu":     "Visualizza _MENU_ elementi",
    "sLoadingRecords": "Caricamento...",
    "sProcessing":     "Elaborazione...",
    "sZeroRecords":    "La ricerca non ha portato alcun risultato.",
    paginate: {
        previous: '&laquo;',
        next: '&raquo;'
    },
    search: "_INPUT_",
    searchPlaceholder: 'Cerca...'
};
// import App from '../Vue/App.vue';
import {default as App} from '../Vue/App.vue';
import {default as routes} from '../Vue/routes';
// import jQuery from 'jquery';
// require(jQuery);
import { initElephant } from './elephant';
import { initApp } from './application';

Vue.prototype.$initElephant = initElephant;
Vue.prototype.$initApp = initApp;
(function () {
    // const store = new Vuex.Store();
    const router = new VueRouter({ mode: 'history', routes });

    new Vue({
        // store,
        router,
        el: '#app',
        render: c => c('app'),
        components: { App },
        template: '<App />', //'<Dashboard :config="' + mainMenu.replace(/"/g, "'") + '"/>',
        // watch: {
        //     $route() {
        //         this.$store.dispatch('dashboard/setNetworkState', null);
        //     }
        // }
    });
    
    initElephant();
    initApp();
})()

Vue.prototype.$formatDate = (d) => { 
    const mom = moment(d);
    if(mom.isValid()){
        return mom.format('DD/MM/YYYY HH:mm');
    } else {
        return '';
    }
}




console.info('main inited');