<template>
  <!-------------------    SIDEBAR   ----------------------->
    <div class="layout-sidebar">
        <div class="layout-sidebar-backdrop"></div>
        <div class="layout-sidebar-body">
            <nav id="sidenav" class="sidenav-collapse collapse">
                <ul class="sidenav">
                    <li class="sidenav-heading">Menu</li>
                    <li class="sidenav-item">
                        <router-link to="/progetti">
                            <span class="sidenav-icon icon icon-product-hunt"></span>
                            <span class="sidenav-label">Progetti</span>
                        </router-link>
                    </li>
                    <li class="sidenav-item">
                        <router-link to="/gruppi">
                            <span class="sidenav-icon icon icon-list"></span>
                            <span class="sidenav-label">Gruppi</span>
                        </router-link>
                    </li>
                    <li class="sidenav-item">
                        <router-link to="/touchpoint">
                            <span class="sidenav-icon icon icon-th"></span>
                            <span class="sidenav-label">Touch Point</span>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!-------------------    SIDEBAR   ----------------------->
</template>

<script>
export default {

}
</script>

<style>

</style>