<template>
  <div>
    <div class="title-bar">
        <div>
            <button class="btn btn-warning btn-labeled pull-right" type="button" @click="newGruppo()">
                <span class="btn-label">
                    <span class="icon icon-plus icon-lg icon-fw"></span>
                </span>
                Nuovo
            </button>
        </div>
        <h1 class="title-bar-title">
            Gruppi
        </h1>
        <p class="title-bar-description">
            <small>Gestione gruppi di touch point</small>
        </p>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="table-responsive">
                        <table id="dt_gruppi" class="table table-middle nowrap">
                            <thead>
                            <tr>
                                <!-- <th>Id</th> -->
                                <th width="90%">Nome</th>
                                <!-- <th>Gruppo Padre</th> -->
                                <th class="dt-no-sort"><i class="icon icon-cog"></i></th>
                            </tr>
                            </thead>
                            <tbody id="dtbody">
                            <!-- <tr v-if="items.length==0">
                                <td colspan="3">Nessun gruppo trovato</td>
                            </tr> -->
                            <tr v-for="i in items.filter(I => I.gruppi_id==null)" :key="i.id">
                                <!-- <td>{{i.id}}</td> -->
                                <td>{{i.nome}}</td>
                                <!-- <td>{{i.gruppo!=null ? i.gruppo.nome : ''}}</td> -->
                                <td>
                                    <router-link :to="'/sottogruppi/gruppo/' + i.id">
                                    <!-- <i class="icon icon-sotto">&nbsp;S&nbsp;</i> -->
                                    <i class="icon icon-folder-open-o"></i>
                                    </router-link>
                                    &nbsp;&nbsp;
                                    <i @click="edit(i.id)" class="icon icon-edit"></i>
                                    &nbsp;&nbsp;
                                    <i @click="remove(i.id)" class="icon icon-remove"></i>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    
    <div class="modal fade bs-modal-lg modal-scroll" id="dlgForm" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"></button>
                    <h4 class="modal-title">Gruppo</h4>
                </div>
                
                <div class="modal-body">
                    <div class="row agutter-lg">
                        <!--
                        <div class="col-md-offset-1 col-md-1 md-form-group  md-label-static">
                            <input class="md-form-control" type="number" v-model="item.id" value="0" readonly>
                            <label class="md-control-label">Id</label>
                        </div>
                        -->
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-10 md-form-group md-label-static">
                            <input class="md-form-control" type="text" v-model="item.nome" required>
                            <label class="md-control-label">Nome</label>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <!-- <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 md-form-group md-label-static">
                            <select class="md-form-control" v-model="item.gruppi_id">
                                <option :value="null"></option>
                                <option v-for="i in items.filter(f => f.id!=item.id)" :key="i.id" :value="i.id">{{i.nome}}</option>
                            </select>
                            <label class="md-control-label">Gruppo padre</label>
                        </div> -->
                        
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" @click="saveItem()">Salva</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>    
    

  </div>
</template>
<script>
export default {
    data(){
        return {
            dataTable: null,
            items: [],
            item: {
                id: null,
                nome: '',
                gruppi_id: null
            }
        }
    },
    methods: {
        initDatatable(){
            // setTimeout(() => {
            if(this.dataTable==null){
                    this.dataTable = $('#dt_gruppi').DataTable({
                        language: this.$dt_language
                    });
                } else {
                    // this.dataTable.destroy();
                    this.$forceUpdate();
                    this.$nextTick(() => this.dataTable = $('#dt_gruppi').DataTable({
                        language: this.$dt_language
                    }));
                }
            // }, 300);

        },
        newGruppo(){
            this.item = {
                id: null,
                nome: '',
                gruppi_id: null
            };
            this.$showModal('dlgForm');
        },
        edit(id){
            this.item = this.items.find(A => A.id==id);
            this.$showModal('dlgForm');
        },
        remove(id){
            Swal.fire({
            title: 'Vuoi eliminare il gruppo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No, grazie',
            }).then((result) => {
                if (result.value) {
                    fetch('/gruppi/delete/' + id, {
                        method: 'DELETE',
                    }).then(res => {
                        if(res.ok){
                            this.dataTable.destroy();
                            const ix = this.items.findIndex(A => A.id==id);
                            this.items.splice(ix, 1);
                            this.$nextTick(() => this.initDatatable());
                        } else {
                            toastr.error(res.statusText, 'Errore');
                        }
                    }).catch(err => {
                        toastr.error(err, 'Errore');
                    })
                }
            })
        },
        saveItem(){
            fetch('/gruppi/save', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(this.item)
            }).then(res => {
                if(res.ok){
                    res.json().then(r => {
                        this.dataTable.destroy();
                        if(this.item.id==null){
                            this.items.push(r);
                        } else {
                            const ix = this.items.findIndex(A => A.id==this.item.id);
                            this.items[ix] = r;
                        }
                        this.$forceUpdate();
                        this.$hideModal();
                        this.$nextTick(() => this.initDatatable());
                        toastr.success('Salvataggio Effettuato', this.$app_title);
                    });
                } else {
                    toastr.error(res.statusText, 'Errore');
                }
            }).catch(err => {
                toastr.error(err, 'Errore');
            });
        }
    },
    mounted(){
        console.info('Gruppo mounted');
        fetch('/gruppi/list').then(res => {
            res.json().then(r => { 
                this.items = r;
                this.$nextTick(() => this.initDatatable());
            });
        });
        this.$initApp();
        this.$initElephant();
    }
}
</script>

<style scoped>
.sotto {
    
}
</style>