var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "title-bar" }, [
      _c(
        "div",
        [
          _c(
            "button",
            {
              staticClass: "btn btn-warning btn-labeled pull-right",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.newGruppo()
                }
              }
            },
            [_vm._m(0), _vm._v("\n              Nuovo\n          ")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn-default btn-labeled pull-right m-r-sm",
              attrs: { type: "button", to: "/gruppi" }
            },
            [
              _c("span", { staticClass: "btn-label" }, [
                _c("span", { staticClass: "icon icon-close icon-lg icon-fw" })
              ]),
              _vm._v("\n              Chiudi\n          ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.gruppo != null
        ? _c("h1", { staticClass: "title-bar-title" }, [
            _vm._v(
              "\n          Sottogruppi del Gruppo " +
                _vm._s(_vm.gruppo.nome) +
                "\n      "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c("div", { staticClass: "panel" }, [
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass: "table table-middle nowrap",
                  attrs: { id: "dt_gruppi" }
                },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { attrs: { id: "dtbody" } },
                    _vm._l(
                      _vm.items.filter(function(I) {
                        return I.gruppi_id == _vm.gruppo.id
                      }),
                      function(i) {
                        return _c("tr", { key: i.id }, [
                          _c("td", [_vm._v(_vm._s(i.nome))]),
                          _vm._v(" "),
                          _c("td", [
                            _c("i", {
                              staticClass: "icon icon-edit",
                              on: {
                                click: function($event) {
                                  return _vm.edit(i.id)
                                }
                              }
                            }),
                            _vm._v(
                              "\n                                    \n                                  "
                            ),
                            _c("i", {
                              staticClass: "icon icon-remove",
                              on: {
                                click: function($event) {
                                  return _vm.remove(i.id)
                                }
                              }
                            })
                          ])
                        ])
                      }
                    ),
                    0
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade bs-modal-lg modal-scroll",
        attrs: {
          id: "dlgForm",
          tabindex: "-1",
          role: "dialog",
          "aria-hidden": "true"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row agutter-lg" }, [
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-10 md-form-group md-label-static" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.nome,
                          expression: "item.nome"
                        }
                      ],
                      staticClass: "md-form-control",
                      attrs: { type: "text", required: "" },
                      domProps: { value: _vm.item.nome },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.item, "nome", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "md-control-label" }, [
                      _vm._v("Nome")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.saveItem()
                    }
                  }
                },
                [_vm._v("Salva")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "button", "data-dismiss": "modal" }
                },
                [_vm._v("Chiudi")]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "btn-label" }, [
      _c("span", { staticClass: "icon icon-plus icon-lg icon-fw" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "title-bar-description" }, [
      _c("small", [_vm._v("Gestione sottogruppi di touch point")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "90%" } }, [_vm._v("Nome")]),
        _vm._v(" "),
        _c("th", { staticClass: "dt-no-sort" }, [
          _c("i", { staticClass: "icon icon-cog" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("button", {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-hidden": "true"
        }
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Sottogruppo")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }