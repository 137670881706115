// import Home from '../components/Home.vue';
import TouchPoint from '../components/TouchPoint.vue';
import Gruppo from '../components/Gruppo.vue';
import Sottogruppo from '../components/Sottogruppo.vue';
import Progetto from '../components/Progetto.vue';
import ProgettoEdit from '../components/ProgettoEdit.vue';
import Utente from '../components/Utente.vue';

const ROUTES = [
    {path: '/', component: Progetto},
    {path: '/touchpoint', component: TouchPoint},
    {path: '/gruppi', component: Gruppo},
    {path: '/sottogruppi/gruppo/:gruppi_id', component: Sottogruppo},
    {path: '/progetti', component: Progetto},
    {path: '/progetti/dettaglio/:id?', component: ProgettoEdit},
    {path: '/utenti', component: Utente},
];


export default ROUTES;

